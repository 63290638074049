import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from "./views/Home";
import Event from "./views/Step10_Event";
import RecommendedEvents from "./views/Step10_RecommendedEvents";
import Address from "./views/Step20_Address";
import Checkout from "./views/Step30_Checkout";
import Confirmation from "./views/Step40_Confirmation";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/de/home" replace />} />
                <Route path="/:language/home" element={<Home />} />
                <Route path="/:language/event/:eventId" element={<Event />} />
                <Route path="/:language/recommended-events" element={<RecommendedEvents />} />
                <Route path="/:language/address" element={<Address />} />
                <Route path="/:language/checkout" element={<Checkout />} />
                <Route path="/:language/confirmation/:orderId" element={<Confirmation />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
