import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getEventById, getEventInfosByEventId, getTicketTypesByEventId, getTicketTypeInfosByTicketTypeId, getTicketTypeNumberOfAvailableTickets } from "ticketino-api-client";

import Header from "./components/Header";
import "../css/style.css";
import Footer from "./components/Footer";
import Loading from "./components/Loading";

const Event = () => {
    const [event, setEvent] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [selectedTicketTypes] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));
    const [moreInfo, setMoreInfo] = useState({});
    const [loading, setLoading] = useState(true);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();
    const { eventId } = useParams();

    //We have to use the selected calendar event in the recomended event page
    sessionStorage.setItem("selctedCalendarEventId", eventId);

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadEvent(eventId, orderId)
        }
    }, [orderId, language]); //gets called when an order is started

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadEvent = async (eventId, orderId) => {
        try {
            setLoading(true);

            // Event
            const event = await getEventById(eventId);
            const infos = await getEventInfosByEventId(eventId);

            // sorting it according to the language
            const eventInfo = infos.eventInfos.find(info => info.languageId == languageId);

            // setting the eventInfo object in the info attribute of event
            const updatedEventInfo = { ...event, info: eventInfo };

            // TicketType
            const ticketTypes = await getTicketTypesByEventId(eventId);

            const updatedTicketTypes = await Promise.all(
                ticketTypes.map(async (ticketType) => {
                    const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);

                    // sorting it according to the language
                    const ticketTypeInfo = info.find(info => info.languageId == languageId);

                    const ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(ticketType.id, orderId);

                    // setting the ticketTypeInfo and ticketTypeAvailability object as attributes in ticketType (info and availability)
                    return { ...ticketType, info: ticketTypeInfo, availability: ticketTypeAvailability }
                })
            )

            // setting the values in the end
            setEvent(updatedEventInfo);
            setTicketTypes(updatedTicketTypes);
            setLoading(false);

        } catch (error) {
            console.error(error);
            navigate(-1);
        }
    }

    const addTicketToBasket = async () => {
        let addTicketTypes = [];

        addTicketTypes = selectedTicketTypes.filter((stt) => stt.quantity !== "0");

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                navigate(`/${language}/recommended-events`);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const onSubmit = async () => {
        await addTicketToBasket();
    };

    const onTicketTypeChange = (e, ticketTypeId) => {
        const obj = {
            ticketTypeId: ticketTypeId,
            quantity: e.target.value,
        };

        const existingTicketType = selectedTicketTypes.find(
            (t) => t.ticketTypeId === ticketTypeId
        );

        if (existingTicketType) {
            existingTicketType.quantity = e.target.value;
        } else {
            selectedTicketTypes.push(obj);
        }

        // calcualate quantity and validate
        let quantity = 0;

        selectedTicketTypes.map(s => {
            quantity += s.quantity * 1;
        });

        if (quantity > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const textContainer = () => {
        let eventStart = "";

        if (event.start) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            eventStart = new Date(event?.start).toLocaleDateString(`de`, options);
        }

        return (
            <div className="fs-6 text-container mb-3 row">
                <div className="col-6 text-start">{resources?.BuyTickets}</div>
                <div className="col-6 text-end fs-6">{(eventId == 183959 || eventId == 186793 || eventId == 193133) ? "" : eventStart}</div>
            </div>
        )
    }

    const mapTicketTypes = () => {
        let ticketTypesSorted = [];

        if (ticketTypes && ticketTypes.length > 0) {
            ticketTypesSorted = [...ticketTypes].sort((a, b) => a.sortOrder - b.sortOrder);
        }

        return (
            ticketTypes &&
            ticketTypes.length > 0 &&
            ticketTypesSorted.map((tt, index) => (
                <div key={index}>
                    {
                        // tt.availability?.status === 0 &&
                        //we want to hide 'Last minute' ticket type. If it is not active, we can't sell ticket in tageskasse. 
                        //otherwise we should show as per pos list.
                        (tt.info?.name?.toLowerCase() !== "last minute" &&
                            (
                                <div className={"row pt-2 pb-2" + (tt.availability?.availableTickets > 0 ? "" : " ticket-disabled")}>
                                    <div className="col-md-8 col-6">
                                        <label className="fs-6">
                                            {tt.info?.name}
                                        </label>
                                        <div className="description">
                                            <span onClick={() => setMoreInfo({ [tt.id]: !moreInfo[tt.id] })}>{resources.OtherInfos} <i class={"bi bi-chevron-" + (moreInfo[tt.id] ? "down" : "up")}></i></span><br></br>
                                            {moreInfo[tt.id] && tt.info?.description}
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-3 text-center">
                                        {(index > 0 || eventId == 193133) && <label>
                                            {tt.currency} {tt.price}.-
                                        </label>}
                                    </div>
                                    <div className="col-md-2 col-3">
                                        <select
                                            className="form-select"
                                            onChange={(e) => onTicketTypeChange(e, tt.id)}
                                        >
                                            {Array.from(
                                                Array(
                                                    tt.availability?.availableTickets + 1
                                                ),
                                                (e, i) => {
                                                    return (
                                                        <option key={i} value={i}>
                                                            {i}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                </div>
                            ))
                    }
                </div>
            ))
        );
    };

    return (
        <div className="container wrapper">
            <Header resources={resources} recommendedEventsHeader={false} />
            <div className="mt-4">
                <div>
                    <div className="container">
                        {textContainer()}
                    </div>
                    <div>{!loading ? mapTicketTypes() : <Loading bgColor="#fff" color="#eb6983" />}</div>
                </div>
                <div className="row mt-4 mb-4">
                    <div className=" col-6 text-start">
                        <button className="button" onClick={() => navigate(-1)}>
                            {resources?.Back}
                        </button>
                    </div>
                    <div className="col-6 text-end">
                        <button className="button" disabled={disabled} onClick={() => onSubmit()}>
                            {resources?.Next}
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Event