import React from 'react'
import { useParams } from 'react-router-dom'

const Footer = () => {

    const { language } = useParams();

    return (
        <div>
            <p className="mt-5 text-center">
                <small style={{ color: "#ffa85b" }}>
                    powered by{" "}
                    <a style={{ color: "#ffa85b", textDecoration: "none" }}
                        target="_blank"
                        href={"https://organizer.ticketino.com/" + language} >
                        TICKETINO
                    </a>
                </small>
            </p>
        </div>
    )
}

export default Footer