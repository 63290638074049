import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getTicketTypeById, getTicketTypeInfosByTicketTypeId } from "ticketino-api-client";
import parse from "html-react-parser";

import Header from "./components/Header";
import "../css/style.css";
import Footer from "./components/Footer";

const Checkout = () => {
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));
    const [posId, setPosId] = useState(0);

    const [order, setOrder] = useState({});
    const [tickets, setTickets] = useState([]);
    const [payment, setPayment] = useState({
        paymentMethodId: 0,
        deliveryMethodId: 0,
        totalPrice: 0,
        currency: "CHF"
    })

    const [insurance, setInsurance] = useState(false);
    const [insuranceAmount, setInsuranceAmount] = useState(0);
    const [hasInsurance, setHasInsurance] = useState(false)

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        let reloadPageAfterSessionTimeOut = sessionStorage.getItem("reloadPageAfterSessionTimeOut");

        if (reloadPageAfterSessionTimeOut == "true") {            
            alert("Ihre Sitzung ist abgelaufen. Bitte beginnen Sie erneut");
            sessionStorage.setItem("reloadPageAfterSessionTimeOut", false);
        }
    }, []);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        requestFormSettings();
    }, [language]); //only on first page load

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            setPosId(res.data.posId);
            loadOrder(orderId, res.data.posId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);

            const bookedTickets = order.tickets;

            // Booked Tickets
            const updatedTickets = await Promise.all(
                bookedTickets.map(async (ticket) => {
                    const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                    const info = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeInfo = info.find(info => info.languageId == languageId);

                    return { ...ticket, ticketType: ticketType, info: ticketTypeInfo }
                })
            )

            setPayment({ ...payment, totalPrice: order.totalPrice, deliveryMethodId: order.deliveryMethodId, paymentMethodId: order.paymentMethodId })
            setInsuranceAmount(order.totalInsuranceAmount);

            if (order.totalInsuranceAmount > 0) {
                setHasInsurance(true);
                setInsurance(true);
            }

            setOrder(order);
            setTickets(updatedTickets);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const changeDeliveryType = async (deliveryMethodId) => {
        await axios.put(
            `${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/${deliveryMethodId}`)
            .then(() => {
                loadOrder(orderId, posId);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const changePaymentType = (paymentMethodId) => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/${paymentMethodId}`)
            .then((res) => {
                loadOrder(orderId, posId);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const startDatatrans = () => {
        var baseRequestUrl = `https://datatrans.ticketino.com/Datatrans/${orderId}/DigitalSignature`;

        axios
            .get(baseRequestUrl)
            .then((res) => {
                let datatransFormId = "datatrans-" + new Date().getTime();
                var form =
                    "<form class='datatrans' id='" + datatransFormId + "'></form>";

                let container = document.getElementById("datatrans-form-placeholder");

                container.innerHTML += form;

                let element = document.getElementById(datatransFormId);

                // merchantId for testing
                // element.setAttribute("data-merchant-id", "1100004624");
                element.setAttribute("data-merchant-id", res.data.merchantId);
                element.setAttribute("data-amount", res.data.amount);
                element.setAttribute("data-currency", res.data.currency);
                element.setAttribute("data-refno", res.data.referenceNumber);
                element.setAttribute("data-reqType", res.data.reqType);
                element.setAttribute(
                    "data-upp-return-target",
                    res.data.uppReturnTarget
                );
                element.setAttribute("data-paymentMethod", res.data.paymentMethod);
                element.setAttribute("data-sign", res.data.digitalSignature);

                let domain = "https://" + window.location.host;

                let successUrl = domain + `/form/redirect/${language}/success/${orderId}`;
                let errorUrl = domain + `/form/redirect/${language}/error/${orderId}`;
                let cancelUrl = domain + `/form/redirect/${language}/cancel/${orderId}`;

                element.setAttribute("data-success-url", successUrl);
                element.setAttribute("data-error-url", errorUrl);
                element.setAttribute("data-cancel-url", cancelUrl);

                for (const key in res.data.userInfo) {
                    element.setAttribute(key, res.data.userInfo[key]);
                }

                for (const key in res.data.merchantSpecificParameters) {
                    element.setAttribute(key, res.data.merchantSpecificParameters[key]);
                }

                // start datatrans -> call the payment form
                window.Datatrans.startPayment({
                    form: "#" + datatransFormId,
                });
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    const checkout = async () => {
        let updatedOrder = {};

        if (insurance) {
            updatedOrder = await setOrderInsurance();
        } else {
            updatedOrder = await getOrderByOrderId(orderId);
        }

        if (updatedOrder?.totalPrice > 0) {
            let dataTransProviders = [1, 7, 8, 9, 10, 14];

            let datatransProvider = dataTransProviders.findIndex((d) => d === payment.paymentMethodId) !== -1

            if (datatransProvider) {
                startDatatrans();

                const timer = setTimeout(() => {
                    window.location.reload();
                    sessionStorage.setItem("reloadPageAfterSessionTimeOut", true);
                }, 600000); // 300'000 (5 minutes)

                return () => {
                    clearTimeout(timer);
                };
            }
        } else {
            changePaymentTypeToFree();
        }
    }

    const setOrderInsurance = async () => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Insurance`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    }

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
            return res.data;
        } catch (error) {
            console.error(error)
        }
    };

    const changePaymentTypeToFree = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(async () => {
                await confirmShopbasketByOrderId(orderId);
                navigate(`/${language}/confirmation/${orderId}`);
            });
    }

    const calculateInsurance = (condition) => {
        if (!hasInsurance) {
            let amount = 0.0;

            tickets.map(ticket => {
                if (ticket.price < 50 && ticket.price > 0) {
                    amount = amount + 0.7;
                } else if (ticket.price > 50 && ticket.price < 75) {
                    amount = amount + 0.95;
                } else {
                    amount = amount + 0.0;
                }
            })
            setInsuranceAmount(amount);

            if (condition) {
                setInsurance(true);
                setPayment({ ...payment, totalPrice: order.totalPrice + amount });
            } else {
                setInsurance(false);
                setPayment({ ...payment, totalPrice: order.totalPrice });
            }
        }
    }

    const mapTickets = () => {
        let ticketsSorted = [];

        if (tickets && tickets.length > 0) {
            ticketsSorted = [...tickets].sort((a, b) => a.id - b.id);
        }

        return (
            tickets &&
            tickets.length > 0 &&
            ticketsSorted.map((tt, index) => (
                <div key={index}>
                    <div className="row pt-2 pb-2">
                        <div className="col-md-10 col-8 p-0">
                            <label className="fs-6">
                                {tt.info?.name}
                            </label>
                        </div>
                        <div className="col-md-2 col-4 text-end p-0">
                            <label>
                                {tt.ticketType?.currency} {tt.price}.-
                            </label>
                        </div>
                    </div>
                </div>
            ))
        );
    }

    const mapExtraCosts = () => {
        return (
            <div>
                <div className="row pt-2 pb-2">
                    <div className="col-md-10 col-8 p-0">
                        <label className="fs-6">
                            Kosten für Lieferung und Zahlung
                        </label>
                    </div>
                    <div className="col-md-2 col-4 text-end p-0">
                        <label>
                            {order.currency + " " + order?.deliverPaymentFee.toFixed(2)}
                        </label>
                    </div>
                </div>
            </div>
        )
    }

    const mapInsurance = () => {
        return (
            <div>
                <div className="row pt-2 pb-2">
                    <div className="col-md-10 col-8 p-0">
                        <label className="fs-6">
                            {resources.TicketInsurance}
                        </label>
                    </div>
                    <div className="col-md-2 col-4 text-end p-0">
                        <label>
                            {order.currency + " " + insuranceAmount.toFixed(2)}
                        </label>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container wrapper">
            <div id="datatrans-form-placeholder"></div>
            <Header />
            <div className="mt-4">
                <div>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <p className="fs-6 text-container mb-3">{resources.DeliveryOption}</p>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="form-check mb-3">
                            <input
                                className="form-check-input me-2"
                                type="radio"
                                name="flexRadioDefault1"
                                checked={payment.deliveryMethodId === 1 ? true : false}
                                onClick={() => changeDeliveryType(1)}
                                id="printAtHome"
                            />
                            <label
                                className="form-check-label fs-6"
                                htmlFor="flexRadioDefault1"
                            >
                                {resources.PrintAtHome}
                                <p className="pt-1 m-0 text-secondary">
                                    {resources.PrintAtHomeDescription}
                                </p>
                            </label>
                        </div>
                    </div>

                    {
                        payment.totalPrice > 0 &&
                        (
                            <>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <p className="fs-6 text-container mb-3">{resources.PaymentOption}</p>
                                    </div>
                                </div>
                                <div className="mt-2 mb-5">
                                    {/* <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2 "
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 11 ? true : false}
                                            onClick={() => changePaymentType(11)}
                                            id="sofort"
                                        />
                                        <label
                                            className="form-check-label fs-6"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            {resources.SOFORT}
                                            <p className="pt-1 m-0 text-secondary">
                                                {resources.SOFORTDesc}
                                            </p>
                                        </label>
                                    </div> */}
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 10 ? true : false}
                                            onClick={() => changePaymentType(10)}
                                            id="paypal"
                                        />
                                        <label
                                            className="form-check-label fs-6"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            {resources.PayPal}
                                            <p className="pt-1 m-0 text-secondary">
                                                {resources.PayPalDesc}
                                            </p>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 1 ? true : false}
                                            onClick={() => changePaymentType(1)}
                                            id="mastercard"
                                        />
                                        <label
                                            className="form-check-label fs-6"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            {resources.MasterCard}
                                            <p className="pt-1 m-0 text-secondary">
                                                {resources.MasterCardDesc}
                                            </p>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 7 ? true : false}
                                            onClick={() => changePaymentType(7)}
                                            id="visa"
                                        />
                                        <label
                                            className="form-check-label fs-6"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            {resources.Visa}
                                            <p className="pt-1 m-0 text-secondary">
                                                {resources.VisaDesc}
                                            </p>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 8 ? true : false}
                                            onClick={() => changePaymentType(8)}
                                            id="amex"
                                        />
                                        <label
                                            className="form-check-label fs-6"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            {resources.AmericanExpress}
                                            <p className="pt-1 m-0 text-secondary">
                                                {resources.AmericanExpressDesc}
                                            </p>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 9 ? true : false}
                                            onClick={() => changePaymentType(9)}
                                            id="postfinance"
                                        />
                                        <label
                                            className="form-check-label fs-6"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            {resources.PostFinance}
                                            <p className="pt-1 m-0 text-secondary">
                                                {resources.PostFinanceDesc}
                                            </p>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="flexRadioDefault"
                                            checked={payment.paymentMethodId === 14 ? true : false}
                                            onClick={() => changePaymentType(14)}
                                            id="twint"
                                        />
                                        <label
                                            className="form-check-label fs-6"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            {resources.Twint}
                                            <p className="pt-1 m-0 text-secondary">
                                                {resources.TwintDesc}
                                            </p>
                                        </label>
                                    </div>
                                </div>
                            </>
                        )}

                    {order.totalPrice > 0 && <div className="row">
                        <div className="col-md-12 mb-3">
                            <p className="fs-6 text-container mb-3">{resources.TicketInsurance}</p><div class="form-check mb-4">
                                <input
                                    className="form-check-input me-3 "
                                    type="checkbox"
                                    checked={insurance}
                                    onChange={(e) => calculateInsurance(e.target.checked)}
                                />
                                <label
                                    className="col-11 form-check-label fs-6"
                                    for="inlineCheckbox1"
                                >
                                    {parse(resources.TicketInsuranceDesc ?? "")}
                                </label>
                            </div>

                        </div>
                    </div>}

                    <div className="row">
                        <div className="col-md-12 mb-1">
                            <p className="fs-6 text-container mb-3">{resources.Summary}</p>
                            <div className="container">
                                {mapTickets()}
                                {insurance && mapInsurance()}
                                {order.deliverPaymentFee > 0 && mapExtraCosts()}
                                <hr className="custom-hr"></hr>
                                <div className="row mb-3">
                                    <div className="col-md-10 col-8 p-0">
                                        <label className="fs-6 fw-bold">
                                            {resources.Total}
                                        </label>
                                    </div>
                                    <div className="col-md-2 col-4 text-end p-0">
                                        <label className="fw-bold">
                                            {order.currency} {payment.totalPrice?.toFixed(2)}
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div>
                        <p className="mb-4">{parse(resources.AGB ?? "")}</p>
                    </div>
                    <div className="row mt-4 mb-4">
                        <div className=" col-6 text-start">
                            <button className="button" onClick={() => navigate(-1)}>
                                {resources?.Back}
                            </button>
                        </div>
                        <div className="col-6 text-end">
                            <button className="button" onClick={() => checkout()}>{resources.Buy}</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Checkout